import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';
import Logger from 'js-logger';
import moment from 'moment';
import { useSelector } from 'react-redux';

import CompletionEntry from './WorkoutEntriesCompletion';
import WorkoutDataEntryTable from './WorkoutDataEntryTable';

const WorkoutEntries = ({
  athleteId,
  activeDate,
  entriesContainerRef,
  entriesViewHeight,
  entriesViewRef,
  entryRowRef,
  formikRef,
  index,
  isWeightValueEnabled,
  handleInfoClick,
  namespace,
  supersetIndex,
  updateProgramWorkout,
  updateWorkout,
  workout,
  workoutsBySuperset,
  wrvSupersetsEnabled,
}) => {
  const accountCode = useSelector(
    (state) => state.weightRoomView.data.currentUser.accountCode
  );
  const currentNonDateDay = useSelector(
    (state) => state.weightRoomView[namespace].currentNonDateDay
  );
  const currentSelectedProgram = useSelector(
    (state) => state.weightRoomView[namespace].currentSelectedProgram
  );
  const isProgramView = useSelector(
    (state) => state.weightRoomView[namespace].isProgramView
  );

  const isCompletion = workout.completionCheckbox;
  const isWrvSupersetsEnabled = wrvSupersetsEnabled;

  const supersetWorkout =
    wrvSupersetsEnabled && workoutsBySuperset && workout.groupingLetter !== null
      ? workoutsBySuperset[workout.groupingLetter][supersetIndex]
      : {};

  const { requiredValuesForAutosave } = workout;

  return (
    <div className="entry_reps" ref={entriesContainerRef}>
      <div className="entries_view_wrapper" ref={entriesViewRef}>
        <Formik
          key={workout.assignedId || workout.saveDataId}
          ref={formikRef}
          initialValues={
            isCompletion
              ? (() => ({
                  accountCode,
                  assignedId: workout.assignedId || workout.saveDataId,
                  userId: athleteId,
                  body: {
                    complete: workout.fullyCompleted ? '1' : '0',
                  },
                  assignedDate: moment(activeDate).format('YYYY-MM-DD'),
                }))()
              : {
                  accountCode,
                  assignedId: workout.assignedId || workout.saveDataId,
                  userId: athleteId,
                  assignedDate: moment(activeDate).format('YYYY-MM-DD'),
                  body: workout.tableData.map((set) => ({
                    setId: set.setId,
                    // Use the reduce() method to loop through each item in the set.values array
                    ...set.values.reduce((accumulator, value) => {
                      // Extract the key (property name) from the current value object
                      const key = value.valueName;
                      const currentValue =
                        !value.value &&
                        isWeightValueEnabled &&
                        value.placeholder &&
                        !Number.isNaN(Number(value.placeholder))
                          ? value.placeholder
                          : value.value;
                      // Return a new object combining the accumulator object and the new key-value pair
                      // This is done using the spread syntax to merge
                      // the accumulator and the new property
                      return { ...accumulator, [key]: currentValue };
                      // Start with an empty object as the initial value for the accumulator
                    }, {}),
                  })),
                }
          }
          onSubmit={(values, actions) => {
            if (isCompletion) {
              if (isProgramView) {
                updateProgramWorkout(
                  index,
                  namespace,
                  currentSelectedProgram.dayBasedProgramId,
                  currentNonDateDay.trueDayNum,
                  currentNonDateDay.relativeDisplayDayNum - 1,
                  values
                );
              } else {
                updateWorkout(index, namespace, values);
              }
              Logger.debug('Values:', index, namespace, values);
              actions.setSubmitting(false);
            } else {
              let complete;
              const submissionBody = [];

              values.body.forEach((el) => {
                complete = true;
                // Loop through each string in the requiredValues array
                requiredValuesForAutosave.forEach((str) => {
                  // Check if the string is a key in the object
                  // and if the value associated with that key is null

                  if (
                    el[str] !== undefined &&
                    (el[str] === null ||
                      el[str] === '' ||
                      Number.isNaN(Number(el[str])))
                  ) {
                    complete = false;
                  }
                });

                console.log('is complete', { el, complete });

                if (complete) {
                  submissionBody.push(el);
                }
              });

              // Only submit if the required values are present and weren't already submitted
              if (submissionBody.length) {
                // Make a copy of value.body so it doesn't reset during submission process
                const valuesCopy = { ...values };
                // Set valuesCopy.body with submissionBody to make sure we are sending complete sets
                valuesCopy.body = submissionBody;
                if (isProgramView) {
                  updateProgramWorkout(
                    index,
                    namespace,
                    currentSelectedProgram.dayBasedProgramId,
                    currentNonDateDay.trueDayNum,
                    currentNonDateDay.relativeDisplayDayNum - 1,
                    valuesCopy
                  );
                } else {
                  updateWorkout(index, namespace, valuesCopy);
                }
                Logger.debug('Values:', index, namespace, valuesCopy);
                actions.setSubmitting(false);
              }
            }
          }}
          render={(props) => (
            <Form>
              <FieldArray
                name="body"
                render={() => (
                  <>
                    {/* Show completion checkbox if completionCheckbox is true. */}
                    {workout.formType === 'CompletionOnly' ? (
                      <CompletionEntry
                        handleInfoClick={handleInfoClick}
                        handleSubmit={props.handleSubmit}
                        info={workout.additionalInformation}
                        setFieldValue={props.setFieldValue}
                        subHeader={workout.subTitle}
                        values={props.values}
                      />
                    ) : (
                      // Show workout data entry table if completionCheckbox is false.
                      <WorkoutDataEntryTable
                        entriesViewHeight={entriesViewHeight}
                        formProps={props}
                        handleChange={props.handleChange}
                        handleInfoClick={handleInfoClick}
                        handleSubmit={props.handleSubmit}
                        isWrvSupersetsEnabled={isWrvSupersetsEnabled}
                        supersetWorkout={supersetWorkout}
                        workout={workout}
                        entryRowRef={entryRowRef}
                      />
                    )}
                  </>
                )}
              />
            </Form>
          )}
        />
      </div>
    </div>
  );
};

WorkoutEntries.propTypes = {
  athleteId: PropTypes.number.isRequired,
  entriesContainerRef: PropTypes.instanceOf(Object).isRequired,
  entriesViewHeight: PropTypes.number.isRequired,
  entriesViewRef: PropTypes.instanceOf(Object).isRequired,
  entryRowRef: PropTypes.instanceOf(Object).isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  handleInfoClick: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
  supersetIndex: PropTypes.number,
  updateProgramWorkout: PropTypes.func.isRequired,
  updateWorkout: PropTypes.func.isRequired,
  workout: PropTypes.instanceOf(Object).isRequired,
  workoutsBySuperset: PropTypes.instanceOf(Object).isRequired,
  wrvSupersetsEnabled: PropTypes.number.isRequired,
};

WorkoutEntries.defaultProps = {
  supersetIndex: 0,
};

export default memo(WorkoutEntries);
