import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../shared/components/Layout/Layout';
import HeaderText from '../../shared/components/HeaderText/HeaderText';
import MainContentComponent from './components/MainContentComponent';

const TestPage = () => (
  <Layout>
    <Helmet>
      <title>Journals | TeamBuildr</title>
    </Helmet>
    <HeaderText>
      Test Page!
    </HeaderText>
    <MainContentComponent />
  </Layout>
);

export default TestPage;
